import { Stack } from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import TikTokIcon from './TikTokIcon';

export default function SocialMediaBox({ className }) {
    return (
        <Stack
            className={className}
            direction="row"
            spacing="20px">
            <a href='https://instagram.com/planguru_app'>
                <InstagramIcon sx={{ color: "#ff6ebb" }} />
            </a>
            <a href='https://twitter.com/1m_ceo'>
                <TwitterIcon sx={{ color: "#6ec0ff" }} />
            </a>
            {/*<a href='https://tiktok.com/@planguru'>
                <TikTokIcon color="#ffb66e" width="40px" height="40px"/>
    </a>*/}
        </Stack >
    );
}