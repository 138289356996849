import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

function srcset(image, size, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${size * rows
      }&fit=crop&auto=format&dpr=2 2x`,
  };
}

export default function QuiltedImageList({ className, cols }) {
  return (
    <ImageList
      className={className}
      variant="quilted"
      cols={cols}
      gap={"8px"}
      sx={{
        "margin-top": "0px",
        "overflow": "hidden"
      }}
    >
      {itemData.map((item) => (
        <ImageListItem key={item.img} cols={item.cols || 1} rows={item.rows || 1}>
          <img
            {...srcset(item.img, 121, item.rows, item.cols)}
            alt={item.title}
            loading="lazy"
          />
        </ImageListItem>
      ))}
    </ImageList>
  );
}

const itemData = [
  {
    img: 'https://images.unsplash.com/photo-1549399541-337e17f70ad7',
    title: 'Palm',
    cols: 2,
    rows: 2
  },
  {
    img: 'https://images.unsplash.com/photo-1514996183542-72c207fee1e3',
    title: 'Bball'
  },
  {
    img: 'https://images.unsplash.com/photo-1631635490744-229e551afade',
    title: 'Bball',
    rows: 2
  },
  {
    img: 'https://images.unsplash.com/photo-1591612910792-5bedcedb9cc2',
    title: 'SF',
    cols: 1,
    rows: 2
  },
  {
    img: 'https://images.unsplash.com/photo-1561751788-85fcb8b78413',
    title: 'Yacht',
    cols: 2
  },
  {
    img: 'https://images.unsplash.com/photo-1485872299829-c673f5194813',
    title: 'Party',
  },
  {
    img: 'https://images.unsplash.com/photo-1470225620780-dba8ba36b745',
    title: 'DJing'
  },
  {
    img: 'https://images.unsplash.com/photo-1554907984-15263bfd63bd',
    cols: 2,
    title: 'Museum'
  },
  {
    img: 'https://images.unsplash.com/photo-1587777803265-2942d48153ef',
    title: 'Country',
  }
];