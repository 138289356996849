import './App.css';
import { Typography } from '@mui/material';
import QuiltedImageList from './components/QuiltedImageList';
import EmailBox from './components/EmailBox';
import SocialMediaBox from './components/SocialMediaBox';
import { ReactComponent as Logo } from './assets/logo-blanco.svg';


export default function App() {
  return (
    <div className='App'>
      <div className='cool'></div>

      <div className='container'>        
        <QuiltedImageList className='hero' cols='4' />
        <div className='title'>
          <Logo />
        </div>
        <div className='captions centered'>
          <Typography variant='body2'>Discover cool plans worldwide. 🌍</Typography>
          <Typography variant='body2'>Promote your social media. 📥</Typography>
          <Typography variant='body2'>Go viral. 🔥</Typography>
        </div>
      </div>


      <div id='emailBoxWrapper'>
        <Typography variant='h7' className='centered android'>💚 Coming very soon to Android!</Typography>
        <EmailBox className='centered' />
      </div>

      <SocialMediaBox className='bottom' />
    </div>
  );
}
