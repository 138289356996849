import { Box, TextField, Button, Typography } from '@mui/material';
import { useState } from 'react';

export default function EmailBox({ className }) {
    const [inputBoxState, setInputBoxState] = useState("")
    const [submittedState, setSubmittedState] = useState(false)

    const handleChange = (event) => {
        setInputBoxState(event.target.value)
    }

    const handleSubmit = () => {
        let email = inputBoxState
        setInputBoxState("")
        /*
        Connect to Mailchimp + Thank you message
        */
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "email": email
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch("https://us-central1-planguru-web.cloudfunctions.net/myapi/subscribe", requestOptions)
            .then(response => response.status)
            .then(status => {
                if (status == "200") {
                    setSubmittedState(true)
                }
                else {
                    alert("Please try again!")
                }
            })
            .catch(error => console.log('error', error));
    }

    var comp;
    if (!submittedState) {
        comp = <div className={className}>
            <Box
                component="form"
                sx={{
                    '& > :not(style)': { m: 1, width: '25ch' },
                }}
                noValidate
                autoComplete="off"
            >
                <TextField id="outlined-basic" label="Enter your email" variant="outlined" sx={{
                    input: { color: '#bbbbbb' }
                }} focused onChange={handleChange} value={inputBoxState} />
            </Box>
            <Button variant='contained' onClick={handleSubmit}>Get early access</Button>
        </div>;
    }
    else {
        comp = <div>
            <Typography variant='h5' className={className}>Thanks for registering! 🎉 </Typography>
            <Typography variant='body2' className={className}>You'll receive an email very shortly. 💌 </Typography>
        </div>
    }

    return comp;
}
