import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCI4HAteqB_j469UwdVBj3AOQW5XiQQHPQ",
    authDomain: "planguru-web.firebaseapp.com",
    projectId: "planguru-web",
    storageBucket: "planguru-web.appspot.com",
    messagingSenderId: "358282353366",
    appId: "1:358282353366:web:c18373610789d16b320777",
    measurementId: "G-Q60KTQSHGT"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App />
);
